import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/bun/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/bun/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/assets/images/logo-pax-sapiens-white.png");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/BackendImage/BackendImage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bun/app/src/components/BackToTop/BackToTop.tsx");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/BlockRenderers/CardGroupBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/BlockRenderers/CardGroupCardBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/BlockRenderers/HeroBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/app/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bun/app/src/components/NavBar/NavBar.tsx");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/OEFBlock/OEFBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/bun/app/src/components/Layout/Layout.module.scss");
